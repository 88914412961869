import { useData, useMethods, formatDigits, dayjs, access, notify } from 'utils';
import { getClientsAction, deleteClientAction } from 'actions';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { paginationDefault, dateClientFormat } from 'const';
import { Table, Tag, Popconfirm, Tooltip } from 'antd';
import { Link } from 'components';

export const ClientsTable = (props) => {
	const { pending, setPending } = props;
	const { clientsFilters = {}, clients = {} } = useData();
	const { data = [], meta = {} } = clients;
	const { current_page = 1, total = 0, per_page = 10 } = meta;
	const { __, update } = useMethods();

	const tableChangeHandler = async (pagination, filters, sorter) => {
		setPending(true);

		const tableFilters = {};
		if (sorter.column && sorter.field && sorter.order) {
			tableFilters.order_by = sorter.field;
			tableFilters.order_type = sorter.order === 'ascend' ? 'asc' : 'desc';
		}
		Object.keys(filters).forEach((key) => {
			tableFilters[key] = filters[key];
		});

		if (pagination.current && pagination.pageSize) {
			tableFilters.page = pagination.current;
			tableFilters.per_page = pagination.pageSize;
		}

		const filter = {
			...clientsFilters,
			...tableFilters
		};
		const clients = await getClientsAction(filter);
		setPending(false);

		update({
			clientsFilters: filter,
			clients
		});
	};

	const deleteClientHandler = async (id) => {
		setPending(true);

		const response = await deleteClientAction(id);
		if (response) {
			notify('success', __('Пользователь успешно удален'));
		}
		const clients = await getClientsAction(clientsFilters);
		setPending(false);

		update({
			clients
		});
	};

	return (
		<Table
			id="clients-table"
			rowKey="id"
			className="table"
			bordered
			loading={{
				spinning: pending,
				size: 'large',
				indicator: <LoadingOutlined />
			}}
			scroll={{
				x: 1560
			}}
			columns={[
				{
					title: __('ФИО'),
					dataIndex: 'name',
					render: (text, record) => <Link href={`/clients/${record.id}`}>{text}</Link>,
					width: 150,
					fixed: 'left'
				},
				{
					title: __('Сумма бонус'),
					dataIndex: 'point',
					render: (card) => card || '-',
					width: 150
				},
				{
					title: __('Номер карты'),
					dataIndex: 'card',
					render: (card) => card || '-',
					width: 150
				},

				{
					title: __('День рождения'),
					dataIndex: 'birthday',
					render: (date) => (date ? dayjs(date).format(dateClientFormat) : '-'),
					width: 100
				},
				{
					title: 'Email',
					dataIndex: 'email',
					render: (email) => email || '-',
					width: 150
				},
				{
					title: __('Дата регистрации'),
					dataIndex: 'created_at',
					render: (date) => date || '-',
					sorter: true,
					width: 100
				},
				{
					title: __('Количество заказов'),
					dataIndex: 'orders_total_quantity',
					render: (quantity) => quantity || '-',
					sorter: true,
					width: 100
				},
				{
					title: __('Сумма заказов'),
					dataIndex: 'orders_total_sum',
					render: (sum) => (sum ? `${formatDigits({ number: sum, defaultValue: 0 })} ${__('грн')}` : '-'),
					width: 100
				},
				{
					title: __('Дата посл. заказа'),
					dataIndex: 'last_order_date',
					render: (date) => date || '-',
					sorter: true,
					width: 100
				},
				{
					title: __('Телефон'),
					dataIndex: 'phone',
					render: (phone) => phone || '-',
					width: 120
				},
				{
					title: __('Адрес'),
					dataIndex: 'address',
					render: (address) => address || '-',
					width: 170
				},
				{
					title: __('Примечание'),
					dataIndex: 'notes',
					render: (notes) => notes || '-',
					width: 130
				},
				{
					title: __('В черном списке'),
					dataIndex: 'blacklisted',
					width: 100,
					filters: [
						{ value: 1, text: __('Да') },
						{ value: 0, text: __('Нет') }
					],
					filterMultiple: false,
					render: (text, record) => {
						if (record.blacklisted) {
							return <Tag color="#e31e24">{__('Да')}</Tag>;
						} else {
							return <Tag>{__('Нет')}</Tag>;
						}
					}
				},
				{
					title: __('Действия'),
					width: 80,
					align: 'right',
					render: (_, record) => {
						return access('clients-update') ? (
							<Popconfirm
								title={__('Вы точно хотите удалить клиента?')}
								okText={__('Да')}
								cancelText={__('Отмена')}
								placement="topRight"
								onConfirm={() => deleteClientHandler(record.id)}
							>
								<Tooltip placement="bottomRight" title={__('Удалить клиента')}>
									<button type="button" className="button button--trans-error button--icon-xs">
										<DeleteOutlined />
									</button>
								</Tooltip>
							</Popconfirm>
						) : null;
					}
				}
			]}
			dataSource={data}
			pagination={{
				...paginationDefault,
				current: current_page,
				pageSize: +per_page,
				total
			}}
			onChange={tableChangeHandler}
		/>
	);
};
