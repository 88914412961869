import { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { MainLayout } from 'layouts';
import { useData, useMethods } from 'utils';
import { ClientForm, ClientOrders } from 'components';
import { getClientAction, getClientOrdersAction, getClientPointHistoryAction } from 'actions';
import { PointHistory } from '../components/clients/PointHistory';

export const ClientPage = (props) => {
	const { match = {} } = props;
	const { params = {} } = match;
	const { id } = params;
	const { clientOrders = [] } = useData();
	const { pointHistory = [] } = useData();
	const { __, update } = useMethods();
	const [pending, setPending] = useState(false);

	useEffect(() => {
		(async () => {
			const data = {
				clientId: id
			};
			if (id) {
				setPending(true);
				const [client, clientOrders, pointHistory] = await Promise.all([getClientAction(id), getClientOrdersAction(id), getClientPointHistoryAction(id)]);
				console.log(pointHistory);
				data.client = client;
				data.oldClient = client;
				data.pointHistory = pointHistory;
				data.clientOrders = clientOrders;
				setPending(false);
			}
			update(data);
		})();

		return () => {
			update({
				clientId: null,
				client: {},
				oldClient: {},
				clientOrders: []
			});
		};
	}, []);

	return (
		<MainLayout seo={{ title: `${id ? __(`Редактирование клиента ${id}`) : __('Создание клиента')} | Ikura CRM` }}>
			<Tabs className="tabs" defaultActiveKey="client" animated={false}>
				<Tabs.TabPane tab={__('Данные клиента')} key="client">
					<ClientForm pending={pending} setPending={setPending} />
				</Tabs.TabPane>
				{id && clientOrders.length ? (
					<Tabs.TabPane tab={`${__('История заказов')} (${clientOrders.length})`} key="history">
						<ClientOrders />
					</Tabs.TabPane>
				) : null}
				{id && pointHistory.length ? (
					<Tabs.TabPane tab={`${__('История Бонусы')} (${pointHistory.length})`} key="history_point">
						<PointHistory />
					</Tabs.TabPane>
				) : null}
			</Tabs>
		</MainLayout>
	);
};
