import { Table, Row, Col, Tag } from 'antd';
import { useData, formatDigits, dayjs} from 'utils';
import { paginationDefault, dateClientFormat } from 'const';
import { useMethods } from '../../utils';

export const PointHistory = () => {
	const { pointHistory = [] } = useData();
	const { __ } = useMethods();

	return (
		<Table
			className="table"
			scroll={scroll}
			rowKey={(record) => record.id}
			dataSource={pointHistory}
			pagination={paginationDefault}
			title={() => {
				const orders = {
					count: pointHistory.length,
					total: formatDigits({ number: pointHistory.reduce((sum, order) => sum + order.price, 0) })
				};
				return (
					<Row gutter={12} justify="end">
						<Col>
							<span>
								{__('Всего заказов')}: <strong>{orders.count}</strong>
							</span>
						</Col>
						<Col>
							<span>
								{__('Сумма заказов')}:{' '}
								<strong>
									{orders.total} {__('грн')}
								</strong>
							</span>
						</Col>
					</Row>
				);
			}}
			columns={[
				{
					title: __('Номер заказа'),
					dataIndex: 'order_id',
					render: (dayId) => dayId || '-'
				},
				{
					title: __('Дата и время'),
					dataIndex: 'created_at',
					render: (date) => dayjs(date).format(dateClientFormat)
				},
				{
					title: __('Сумма бонуса'),
					dataIndex: 'value',
					render: (sum) => `${formatDigits({ number: sum, toFixed: 2 })} ${__('грн')}`
				},
				{
					title: __('Статус'),
					dataIndex: 'status',
					render: (status) => {
						return <Tag color={null}>{status}</Tag>;
					}
				}
			]}
		/>
	);
};
