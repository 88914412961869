import { useEffect, useState } from 'react';
import { Form, Tooltip, Row, Col, Select, Input, Radio, DatePicker, InputNumber, AutoComplete } from 'antd';
import {
	useData,
	useMethods,
	getOrderStatuses,
	getOrderTypes,
	getOrderSales,
	getPaymentTypes,
	requiredRule,
	getDeliveryTypes,
	dayjs,
	numberFormatter,
	getDeliveryTimes,
	getAddresses,
	getPhones,
	getCouriers,
	floatFormatter,
	formatDigits,
	access
} from 'utils';
import {
	ClientSearch,
	PhonesChange,
	ClientNameField,
	AddressesChange,
	Preloader,
	OrderStatistic,
	OrderStatusHistory
} from 'components';
import { getDeliveryTimesAction, refreshOrderAction, sipCallAction, calculatePromoCodesAction } from 'actions';
import { EditOutlined, PhoneOutlined } from '@ant-design/icons';
import { dateServerFormat, dateClientFormat } from 'const';

let orderTimeout = null;

export const OrderForm = (props) => {
	const { setPending } = props;
	const {
		orderId,
		oldOrder = {},
		order = {},
		oldClient = {},
		client = {},
		orderStatuses = [],
		orderTypes = [],
		paymentTypes = [],
		orderSales = [],
		deliveryTypes = [],
		deliveryTimes = [],
		couriers = [],
		sipWork,
		promocodes = [],
		cashbox = {}
	} = useData();
	const { __, update } = useMethods();
	const [form] = Form.useForm();
	const [pendingDeliveryTime, setPendingDeliveryTime] = useState(false);
	const [banknoteSum, setBanknoteSum] = useState(0);
	const [payedBonus, setPayedBonus] = useState(0);

	const isPrepared = order.payment_type_id !== 1;
	const canChangeDateTime = [1, 2, 3, 4, 5, 6, 12].includes(order.status_id);
	const isStatusEnd = [9, 10].includes(order.status_id);
	const isDeliveryStatus = [6, 7, 8].includes(order.status_id);
	const isOrderTypeDisabled = order.delivery_type_id === 2;
	const hasProducts = !!order.products?.length;

	const orderStatusesList = getOrderStatuses(orderStatuses, orderTypes, oldOrder.status_id, order.order_type_id, order.delivery_type_id);
	const orderTypesList = getOrderTypes(orderTypes, __);
	const paymentTypesList = getPaymentTypes(paymentTypes);
	const orderSalesList = getOrderSales(orderSales, orderTypes, order.order_type_id, __);
	const deliveryTypesList = getDeliveryTypes(deliveryTypes, orderTypes, order.order_type_id);
	const phoneList = getPhones(client.phones);
	const addressList = getAddresses(client.addresses);
	const deliveryTimesList = getDeliveryTimes(deliveryTimes, order.delivery_date, __);
	const couriersList = getCouriers(couriers);

	useEffect(() => {
		const mainPhone = (oldClient.phones || []).find((item) => item.main);
		const mainAddress = (oldClient.addresses || []).find((item) => item.main);

		form.setFieldsValue({
			...oldOrder,
			phone_id: oldOrder.phone_id
				? oldOrder.phone_id
				: mainPhone
					? mainPhone.id
					: oldClient.phones && oldClient.phones.length
						? oldClient.phones[0].id
						: null,
			address_id: oldOrder.address_id
				? oldOrder.address_id
				: mainAddress
					? mainAddress.id
					: oldClient.addresses && oldClient.addresses.length
						? oldClient.addresses[0].id
						: null,
			name: oldClient.name,
			client_description: oldClient.notes
		});
	}, [oldOrder, oldClient]);

	const banknoteChangeHandler = () => {
		const values = form.getFieldsValue();
		const value = values.banknote || 0;
		setBanknoteSum(value > +order.price ? value - +order.price : 0);
	};
	const bonusPayedHandler = async () => {
		clearTimeout(orderTimeout);
		orderTimeout = setTimeout(async ()=>{
			const values = form.getFieldsValue();
			const value = values.point || 0;
			setPayedBonus(value);
			const orderData = await refreshOrderAction({
				...order,
				point: value
			});
			update({
				order: orderData
			});
		},1000)
	};
	useEffect(() => {
		banknoteChangeHandler();
	}, [order.price]);

	const changeHandler = (changedValues, values) => {
		clearTimeout(orderTimeout);
		orderTimeout = setTimeout(async () => {
			const { name, client_description, ...rest } = values;
			const { delivery_date, delivery_type_id } = rest;
			const updateProps = {};
			const updateForm = {};
			const updateOrder = {};

			if (changedValues.status_id === 12) {
				updateOrder.delivery_date = null;
				updateForm.delivery_date = null;
				updateOrder.delivery_time = null;
				updateForm.delivery_time = null;
			}

			if (changedValues.delivery_date !== undefined || changedValues.delivery_type_id !== undefined) {
				setPendingDeliveryTime(true);
				updateProps.deliveryTimes = await getDeliveryTimesAction(delivery_date, delivery_type_id);
				const existDeliveryTime = updateProps.deliveryTimes.find((time) => time === values.delivery_time);
				if (!existDeliveryTime || dayjs(values.delivery_date).format(dateServerFormat) === dayjs().format(dateServerFormat) && !orderId) {
					updateOrder.delivery_time = updateProps.deliveryTimes[0];
					updateForm.delivery_time = updateProps.deliveryTimes[0];
				}
				setPendingDeliveryTime(false);
			}

			let isPromocode = false;
			if (changedValues.promo_code !== undefined) {
				if (changedValues.promo_code) {
					const {
						percent,
						present
					} = await calculatePromoCodesAction(order.products, changedValues.promo_code);
					if (percent || present) {
						updateOrder.promo_code = changedValues.promo_code;
						isPromocode = true;
					}
				} else {
					updateOrder.promo_code = '';
					isPromocode = true;
				}
			}

			if (changedValues.order_type_id) {
				const newStatusesList = getOrderStatuses(
					orderStatuses,
					orderTypes,
					oldOrder.status_id,
					changedValues.order_type_id,
					order.delivery_type_id
				);
				const newStatusId = newStatusesList.find((item) => item.value === order.status_id)
					? order.status_id
					: newStatusesList[0]
						? newStatusesList[0].value
						: null;
				const newDeliveryTypesList = getDeliveryTypes(deliveryTypes, orderTypes, changedValues.order_type_id);
				const newDeliveryTypeId = newDeliveryTypesList.find((item) => item.value === order.delivery_type_id)
					? order.delivery_type_id
					: newDeliveryTypesList[0]
						? newDeliveryTypesList[0].value
						: null;
				const newSalesList = getOrderSales(orderSales, orderTypes, changedValues.order_type_id, __);
				const newDiscountId = newSalesList.find((item) => item.value === order.discount_id)
					? order.discount_id
					: newSalesList[0]
						? newSalesList[0].value
						: null;
				updateForm.status_id = newStatusId;
				updateOrder.status_id = newStatusId;
				updateForm.delivery_type_id = newDeliveryTypeId;
				updateOrder.delivery_type_id = newDeliveryTypeId;
				updateForm.discount_id = newDiscountId;
				updateOrder.discount_id = newDiscountId;
			}

			if (Object.keys(updateForm).length) {
				form.setFieldsValue({
					...values,
					...updateForm
				});
			}

			let orderData = {
				...order,
				...rest,
				...updateOrder,
				delivery_date: delivery_date ? dayjs(delivery_date).format(dateServerFormat) : null
			};

			if (
				(changedValues.promo_code !== undefined && isPromocode) ||
				(values.delivery_type_id === 1 && changedValues.address_id !== undefined) ||
				changedValues.discount_id !== undefined ||
				changedValues.order_type_id !== undefined ||
				changedValues.delivery_type_id !== undefined
			) {
				setPending(true);
				orderData = await refreshOrderAction(orderData);
				setPending(false);
			}

			update({
				order: orderData,
				client: {
					...client,
					name,
					notes: client_description
				},
				...updateProps
			});
		}, 500);
	};

	const changeClientHandler = async (client = {}) => {
		const phoneId = client.phones && client.phones.length ? client.phones[0].id : null;
		const notes = client.notes && client.notes ? client.notes : null;
		const addressId = client.addresses && client.addresses.length ? client.addresses[0].id : null;
		form.setFieldsValue({
			phone_id: phoneId,
			name: client.name,
			address_id: addressId,
			client_description: notes
		});
		const orderData = await refreshOrderAction({
			...order,
			client_id: client.id,
			phone_id: phoneId,
			address_id: addressId,
			client_description: notes,
			point: payedBonus
		});
		update({
			order: orderData
		});
	};

	const changePhonesHandler = (phones = []) => {
		const data = form.getFieldsValue();
		const phoneIdExist = phones.find((item) => item === data.phone_id);
		if (!phoneIdExist) {
			const phoneId = phones.length ? phones[0].id : null;
			form.setFieldsValue({
				...data,
				phone_id: phoneId
			});
		}
	};

	const changeAddressesHandler = async (addresses = [], index = null) => {
		const data = form.getFieldsValue();
		let addressId = null;
		if (index !== null && addresses.length && addresses[index]) {
			addressId = addresses[index].id;
		} else {
			const addressIdExist = addresses.find((item) => item.id === data.address_id);
			if (!addressIdExist && addresses.length) {
				addressId = addresses[0].id;
			}
		}
		if (addressId) {
			form.setFieldsValue({
				...data,
				address_id: addressId
			});
			const orderData = await refreshOrderAction({
				...order,
				address_id: addressId
			});
			update({
				order: orderData
			});
		}
	};

	const phoneCallHandler = async () => {
		const { phone_id } = form.getFieldsValue();
		const phone = phoneList.find((item) => item.value === phone_id)?.label;
		if (phone) {
			await sipCallAction(phone);
		}
	};

	return (
		<Form className='form' onValuesChange={changeHandler} form={form} layout='vertical' noValidate={true}>
			<div className='order-form'>
				<Row gutter={24}>
					<Col span={24}>
						<div className='order-form__title'>{__('Основное')}</div>
						<Row gutter={12}>
							{!orderId ? (
								<Col span={24} xxl={4}>
									<ClientSearch disabled={isStatusEnd} onChange={changeClientHandler} />
								</Col>
							) : null}
							<Col span={24} md={12} xl={8} xxl={4}>
								<Form.Item className='form__item' name='status_id' label={__('Статус заказа')} required>
									<Select dropdownClassName='form__dropdown' options={orderStatusesList} />
								</Form.Item>
							</Col>
							<Col span={24} md={12} xl={8} xxl={4}>
								<Form.Item className='form__item' name='payment_type_id' label={__('Тип оплаты')}
										   required>
									<Select
										dropdownClassName='form__dropdown'
										disabled={isStatusEnd || (isDeliveryStatus && !access('order-payment-change-while-delivery-in-progress'))}
										options={paymentTypesList}
									/>
								</Form.Item>
							</Col>
							<Col span={24} md={12} xl={8} xxl={4}>
								<Form.Item className='form__item' name='order_type_id' label={__('Тип заказа')}
										   required>
									<Select dropdownClassName='form__dropdown' disabled={isStatusEnd}
											options={orderTypesList} />
								</Form.Item>
							</Col>
							<Col span={24} md={12} xxl={6}>
								<Form.Item className='form__item' name='discount_id' label={__('Скидка к заказу')}
										   required>
									<Radio.Group buttonStyle='solid' optionType='button' disabled={isStatusEnd}
												 options={orderSalesList} />
								</Form.Item>
							</Col>
							{order.status_id === 10 ? (
								<Col span={24} md={12} xxl={6}>
									<Form.Item
										className='form__item form__item--autosize'
										name='rejection_reason'
										label={__('Причина отказа')}
										required
										rules={[requiredRule(__)]}
									>
										<Input.TextArea autoSize={{ minRows: 1, maxRows: 6 }} disabled={!cashbox.id} />
									</Form.Item>
								</Col>
							) : null}
						</Row>
					</Col>
					<Col span={24} xxl={16}>
						<div className='order-form__title'>{__('Данные клиента')}</div>
						<Row gutter={12}>
							<Col span={24} md={12} lg={8}>
								<Row gutter={12} style={{ flexWrap: 'nowrap' }}>
									<Col style={{ width: 'calc(100% - 52px * 2)' }}>
										<Form.Item className='form__item' name='phone_id' label={__('Телефон')} required
												   rules={[requiredRule(__)]}>
											<Select dropdownClassName='form__dropdown' disabled={isStatusEnd}
													options={phoneList} />
										</Form.Item>
									</Col>
									<Col style={{ flexShrink: 0, marginTop: '25px' }}>
										{isStatusEnd ? (
											<button type='button' className='button button--icon-small button--main'
													disabled={true}>
												<EditOutlined />
											</button>
										) : (
											<PhonesChange onChange={changePhonesHandler}>
												<Tooltip placement='topRight'
														 title={__('Редактировать список телефонов')}>
													<button type='button'
															className='button button--icon-small button--main'>
														<EditOutlined />
													</button>
												</Tooltip>
											</PhonesChange>
										)}
									</Col>
									<Col style={{ flexShrink: 0, marginTop: '25px' }}>
										{!sipWork || !phoneList.length || isStatusEnd ? (
											<button type='button' className='button button--icon-small button--main'
													disabled={true}>
												<PhoneOutlined />
											</button>
										) : (
											<Tooltip placement='topRight' title={__('Позвонить')}>
												<button type='button' className='button button--icon-small button--main'
														onClick={phoneCallHandler}>
													<PhoneOutlined />
												</button>
											</Tooltip>
										)}
									</Col>
								</Row>
							</Col>
							<Col span={24} md={12} lg={8} xxl={10}>
								<ClientNameField disabled={isStatusEnd} />
							</Col>
							<Col span={24} md={12} lg={8} xxl={6}>
								<Form.Item className='form__item' name='promo_code' label={__('Промокод')}>
									<AutoComplete
										dropdownClassName='form__dropdown'
										disabled={isStatusEnd || !hasProducts}
										options={promocodes.map((item) => ({ value: item.name, label: item.name }))}
									/>
								</Form.Item>
							</Col>
							<Col span={24} md={12} lg={8} xxl={5}>
								<Form.Item className='form__item' name='delivery_type_id' label={__('Тип доставки')}
										   required>
									<Radio.Group buttonStyle='solid' optionType='button' disabled={isStatusEnd}
												 options={deliveryTypesList} />
								</Form.Item>
							</Col>
							<Col span={24} lg={16} xxl={9}>
								<Row gutter={12} style={{ flexWrap: 'nowrap' }}>
									<Col style={{ width: 'calc(100% - 52px)' }}>
										<Form.Item
											className='form__item'
											name='address_id'
											label={__('Адрес доставки')}
											required
											rules={[requiredRule(__)]}
										>
											<Select
												dropdownClassName='form__dropdown'
												disabled={isStatusEnd || isOrderTypeDisabled}
												options={addressList}
											/>
										</Form.Item>
									</Col>
									<Col style={{ flexShrink: 0, marginTop: '25px' }}>
										{isStatusEnd ? (
											<button type='button' className='button button--icon-small button--main'
													disabled={true}>
												<EditOutlined />
											</button>
										) : (
											<AddressesChange onChange={changeAddressesHandler}>
												<Tooltip placement='topRight'
														 title={__('Редактировать список адресов')}>
													<button type='button'
															className='button button--icon-small button--main'>
														<EditOutlined />
													</button>
												</Tooltip>
											</AddressesChange>
										)}
									</Col>
								</Row>
							</Col>
							<Col span={24} md={12} lg={8} xxl={5}>
								<Form.Item
									className='form__item'
									name='delivery_date'
									label={__('Дата доставки')}
									required
									rules={[requiredRule(__)]}
								>
									<DatePicker
										format={dateClientFormat}
										disabled={!canChangeDateTime || isStatusEnd}
										allowClear={true}
										disabledDate={(current) => current && current < dayjs().startOf('day')}
									/>
								</Form.Item>
							</Col>
							<Col span={24} md={12} lg={8} xxl={5}>
								<Preloader spinning={pendingDeliveryTime}>
									<Form.Item
										className='form__item'
										name='delivery_time'
										label={__('Время доставки')}
										required
										rules={[requiredRule(__)]}
									>
										<Select
											dropdownClassName='form__dropdown'
											disabled={!canChangeDateTime || isStatusEnd}
											options={deliveryTimesList}
										/>
									</Form.Item>
								</Preloader>
							</Col>
							<Col span={24} md={12} lg={8} xxl={6}>
								<Form.Item className='form__item' name='person' label={__('Персон')}>
									<InputNumber min={0} disabled={isStatusEnd} formatter={numberFormatter} />
								</Form.Item>
							</Col>
							<Col span={24} md={12} lg={8} xxl={6}>
								<Form.Item className='form__item' name='cutlery_training' label={__('Палочек учебных')}>
									<InputNumber min={0} disabled={isStatusEnd} formatter={numberFormatter} />
								</Form.Item>
							</Col>
							<Col span={24} md={12} lg={8} xxl={6}>
								<Form.Item className='form__item' name='cutlery_regular' label={__('Палочек обычных')}>
									<InputNumber min={0} disabled={isStatusEnd} formatter={numberFormatter} />
								</Form.Item>
							</Col>
							<Col span={24} md={12} lg={8} xxl={6}>
								<Form.Item className='form__item' name='spoons_forks' label={__('Ложка+вилка')}>
									<InputNumber min={0} disabled={isStatusEnd} formatter={numberFormatter} />
								</Form.Item>
							</Col>
							<Col span={24} xl={12}>
								<Form.Item className='form__item form__item--textarea' name='description'
										   label={__('Примечание к заказу')}>
									<Input.TextArea disabled={isStatusEnd} />
								</Form.Item>
							</Col>
							<Col span={24} xl={12}>
								<Form.Item className='form__item form__item--textarea' name='client_description'
										   label={__('Примечание к клиенту')}>
									<Input.TextArea disabled={isStatusEnd} />
								</Form.Item>
							</Col>
						</Row>
						<OrderStatistic />
					</Col>
					<Col span={24} xxl={8}>
						<Row gutter={24}>
							{order.receiver_name && order.receiver_phone ? (
								<Col span={24} xl={12} xxl={24}>
									<h2>Данные получателя</h2>
									<div style={{ display: 'flex',gap: '41px',fontSize: '15px',fontWeight: '500' }}>
										<p>
											Тел.: {order.receiver_phone}
										</p>
										<p>
											Имя: {order.receiver_name}
										</p>
									</div>
								</Col>
							) : null}

							<Col span={24} xl={12} xxl={24}>
								<OrderStatusHistory />
							</Col>
							<Col span={24} xl={12} xxl={24}>
								<Row gutter={24}>
									<Col span={24} md={12} xl={24}>
										<Form.Item className='form__item' name='courier_id' label={__('Курьер')}>
											<Select
												dropdownClassName='form__dropdown'
												disabled={true}
												options={couriersList}
												placeholder={__('Не выбрано')}
											/>
										</Form.Item>
									</Col>
									{client.point ? (
										<Col span={24} md={12} xl={24}>
											<Form.Item
												className='form__item'
												name='point'
												label={`${__('Бонус')}: ${formatDigits({
													number: client.point,
													toFixed: 2
												})} ${__('грн')}`}
											>
												<InputNumber min={0}
															 max={(order.price * 50 / 100) > client.point ? client.point : order.price * 50 / 100}
															 formatter={floatFormatter} onChange={bonusPayedHandler} />
											</Form.Item>
										</Col>
									) : null}
									{!isStatusEnd && !isPrepared ? (
										<Col span={24} md={12} xl={24}>
											<Form.Item
												className='form__item'
												name='banknote'
												label={`${__('Сдача')}: ${formatDigits({
													number: banknoteSum,
													toFixed: 2
												})} ${__('грн')}`}
											>
												<InputNumber min={0} formatter={floatFormatter}
															 onChange={banknoteChangeHandler} />
											</Form.Item>
										</Col>
									) : null}
								</Row>
								<div className='order-form__sum-block'>
									<div className='order-form__sum-row'>
										<div className='order-form__sum-title'>{__('Сумма заказа')}:</div>
										<div className='order-form__sum-value'>
											{formatDigits({ number: order.price_full || 0, toFixed: 2 })} {__('грн')}
										</div>
									</div>
									<div className='order-form__sum-row'>
										<div className='order-form__sum-title'>{__('Стоимость доставки')}:</div>
										<div className='order-form__sum-value'>
											{formatDigits({ number: order.delivery_sum || 0, toFixed: 2 })} {__('грн')}
										</div>
									</div>
									<div className='order-form__sum-row'>
										<div className='order-form__sum-title'>{__('Скидка')}:</div>
										<div className='order-form__sum-value'>
											{formatDigits({ number: order.discount_sum || 0, toFixed: 2 })} {__('грн')}
										</div>
									</div>

								</div>
								<div className='order-form__total-block'>
									<div className='order-form__total-title'>
										{__('Итого')}: {formatDigits({
										number: order.price || 0,
										toFixed: 2
									})} {__('грн')}
									</div>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		</Form>
	);
};
