import { api, dayjs, getStore } from 'utils';
import { dateServerFormat, pizzaSizes, timeClientFormat, timeServerFormat } from 'const';

export const sendOrderData = (values = {}) => {
	return {
		id: values.id || null,
		payment_type_id: values.payment_type_id || 1,
		order_type_id: values.order_type_id || 2,
		delivery_type_id: values.delivery_type_id || 1,
		discount_id: values.discount_id || null,
		promo_code: values.promo_code || '',
		status_id: values.status_id || 1,
		delivery_date: values.delivery_date ? dayjs(values.delivery_date).format(dateServerFormat) : '',
		delivery_time: values.delivery_time ? dayjs(values.delivery_time, timeClientFormat).format(timeServerFormat) : '',
		phone_id: String(values.phone_id).includes('_') ? 0 : values.phone_id,
		address_id: values.delivery_type_id === 1 ? (String(values.address_id).includes('_') ? 0 : values.address_id) : null,
		client_id: values.client_id || 0,
		person: values.person || 0,
		cutlery_training: values.cutlery_training || 0,
		cutlery_regular: values.cutlery_regular || 0,
		spoons_forks: values.spoons_forks || 0,
		pay_from_points: values.point || 0,
		description: values.description || '',
		rejection_reason: values.rejection_reason || '',
		banknote: values.banknote || null,
		products: (values.products || []).map((product) => ({
			id: product.id,
			quantity: product.quantity || 0,
			is_pizza: !!product.is_pizza,
			is_promo: !!product.is_promo,
			pizza_size: product.pizza_size || pizzaSizes[0].value || 0,
			note: product.note || '',
			ingredients: (product.ingredients || []).map((ingredient) => ({
				id: ingredient.id,
				quantity: ingredient.quantity || 0
			})),
			products: (product.products || []).map((prod) => ({
				id: prod.id,
				quantity: prod.quantity || 0
			}))
		})),
		nomenclatures: (values.nomenclatures || []).map((nomenclature) => ({
			id: nomenclature.id,
			quantity: nomenclature.quantity || 0
		}))
	};
};

export const getOrderData = (values = {}) => {
	const products = getStore('products', []);
	const nomenclatures = getStore('nomenclatures', []);
	return {
		...values,
		status_id: values.status_id || 1,
		order_type_id: values.order_type_id || 2,
		delivery_type_id: values.delivery_type_id || 1,
		payment_type_id: values.payment_type_id || 1,
		discount_id: values.discount_id || 0,
		discount_sum: Math.abs(values.discount_sum || 0),
		delivery_date: values.delivery_date || dayjs().format(dateServerFormat),
		products: (values.products || []).map((product, index) => {
			return {
				...(products.find((item) => item.id === product.id) || {}),
				...product,
				_uid: `product-${index}-${product.id}`
			};
		}),
		nomenclatures: (values.nomenclatures || []).map((nomenclature, index) => ({
			...(nomenclatures.find((item) => item.id === nomenclature.id) || {}),
			...nomenclature,
			isNomenclature: true,
			_uid: `nomenclature-${index}-${nomenclature.id}`
		}))
	};
};

export const getOrdersStatisticAction = async () => {
	const response = await api.get(`/orders-in-progress-statistic`);
	return response.data ? response.data : {};
};

export const getOrdersAction = async () => {
	const response = await api.get(`/orders-in-progress-list`);
	return response.data ? response.data : [];
};

const getParamsForFinishedOrders = (values = {}) => {
	const { page = 1, per_page = 50, delivery_date = [dayjs(), dayjs()], status, custom, with_discount, is_fiscal_printed, ...rest } = values;
	const params = {
		...rest,
		page,
		per_page,
		delivery_date:
			delivery_date[0] && delivery_date[1]
				? `${delivery_date[0].format(dateServerFormat)} - ${delivery_date[1].format(dateServerFormat)}`
				: null
	};
	if (status) {
		params.statuses = [status];
	}
	if (custom) {
		params[custom] = true;
	}
	if (with_discount) {
		params.with_discount = true;
	}
	if (is_fiscal_printed) {
		params.is_fiscal_printed = true;
	}
	return params;
};

export const getFinishedOrdersStatisticAction = async (values = {}) => {
	const response = await api.get(`/orders-finished-statistic`, { params: getParamsForFinishedOrders(values) });
	return response.data ? response.data : {};
};

export const getFinishedOrdersAction = async (values = {}) => {
	const response = await api.get(`/orders-finished-list`, { params: getParamsForFinishedOrders(values) });
	return response || {};
};

export const getDeliveryTimesAction = async (date = dayjs(), deliveryType = 1) => {
	const params = {};
	if (date) {
		params.date = dayjs(date).format(dateServerFormat);
	}
	const response = await api.get(`orders/delivery-time-schedule/${deliveryType}`, { params });
	return response.data || [];
};

export const increaseCookLoadAction = async () => {
	const response = await api.put(`orders/increase-cook-load`);
	return response.success;
};

export const changeStatusAction = async (id, values = {}) => {
	const response = await api.post(`/orders/${id}/change-status`, values);
	return response.success;
};

export const getOrderLogAction = async (id) => {
	const response = await api.get(`/orders/${id}/log`);
	return response.data ? response.data : [];
};

export const getOrderAction = async (id) => {
	const response = await api.get(`/orders/${id}`);
	const data = response.data || {};
	if (data.products) {
		data.products = data.products.map((item) => ({
			...item,
			_uid: `product-${item.id}`
		}));
	}
	if (data.nomenclatures) {
		data.nomenclatures = data.nomenclatures.map((item) => ({
			...item,
			isNomenclature: true,
			_uid: `nomenclature-${item.id}`
		}));
	}
	return getOrderData(data);
};

export const getPromoCodesAction = async () => {
	const response = await api.get(`/promo-codes`);
	return response.data || [];
};

export const calculatePromoCodesAction = async (products, promo_code) => {
	const response = await api.post(`/promo-codes/calculate`, { products, promo_code });
	return response.data ? response.data : {};
};

export const getOrderStatusHistoryAction = async (id) => {
	const response = await api.get(`/orders/${id}/status-history`);
	return response.data ? response.data : [];
};

export const createOrderAction = async (values) => {
	const response = await api.post(`/orders`, values);
	return getOrderData(response.data ? response.data : {});
};

export const updateOrderAction = async (id, values) => {
	const response = await api.put(`/orders/${id}`, values);
	return getOrderData(response.data ? response.data : {});
};

export const refreshOrderAction = async (values) => {
	const response = await api.post(`/orders/refresh`, sendOrderData(values));
	return response.data ? getOrderData(response.data) : values;
};

export const printInvoiceAction = async (id) => {
	const response = await api.get(`/orders/print-invoice/${id}`);
	return response.success;
};

export const printInvoiceClientAction = async (id) => {
	const response = await api.get(`/orders/print-invoice/${id}/client`);
	return response.success;
};

export const finishSelfDeliveryAction = async (id, values) => {
	const response = await api.put(`/orders/${id}/finish-self-delivery`, values);
	return response.success;
};

export const printFiscalInvoiceAction = async (id) => {
	const response = await api.get(`/orders/print-fiscal-invoice/${id}`);
	return response.success;
};

export const printReturnInvoiceAction = async (id) => {
	const response = await api.get(`/orders/print-fiscal-return-invoice/${id}`);
	return response.success;
};
