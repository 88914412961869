import { api, geocode, phoneClear, dayjs } from 'utils';
import { dateServerFormat } from 'const';

export const clientSearchAction = async (params = {}) => {
	const response = await api.get(`/clients-search`, { params });
	return response.data ? response.data : [];
};

export const clientSearchPhoneAction = async (value) => {
	const response = await api.get(`/clients-search-by-phone-or-card`, { params: { search: value } });
	return response.data ? response.data : [];
};

export const clientSearchNameAction = async (value) => {
	const response = await api.get(`/clients-search-name`, { params: { name: value } });
	return response.data ? response.data : [];
};

export const getClientAction = async (id) => {
	const response = await api.get(`/clients/${id}`);
	return response.data ? response.data : {};
};

export const getClientOrdersAction = async (id) => {
	const response = await api.get(`/clients/${id}/orders`);
	return response.data ? response.data : [];
};

export const getClientPointHistoryAction = async (id) => {
	const response = await api.get(`/clients/${id}/point-history`);
	return response.data ? response.data : [];
};

export const getGeoSearchAction = async (value) => {
	let list = [];
	try {
		const response = await geocode.fromAddress(value);
		if (response.results.length) {
			let result = response.results.map((item) => {
				const { lat, lng } = item.geometry.location;
				const streetComponent = item.address_components.filter((item) => item.types.includes('route'));
				const cityComponent = item.address_components.filter((item) => item.types.includes('locality'));
				return {
					title: streetComponent.length ? streetComponent[0].long_name : '',
					searchTitle: streetComponent.length
						? streetComponent[0].long_name + (cityComponent.length ? ', ' + cityComponent[0].long_name : '')
						: '',
					lat,
					lng
				};
			});
			list = result.filter((item) => !!item.title);
		}
	} catch (error) {}
	return list;
};

export const getStreetsSearchAction = async (locale, cityId, name) => {
	const response = await api.get(`/${locale}/${cityId}/streets/search`, {
		baseURL: process.env.REACT_APP_GEO_URL,
		params: {
			name
		}
	});
	return response.data ? response.data : [];
};

export const getAddressesAction = async (locale, restaurantSlug, params) => {
	const response = await api.get(`/${locale}/${restaurantSlug}/addresses`, {
		baseURL: process.env.REACT_APP_GEO_URL,
		params
	});
	return response.data ? response.data : [];
};

export const getDistrictsSearchAction = async (locale, restaurantSlug, params) => {
	const response = await api.get(`/${locale}/${restaurantSlug}/districts/search`, {
		baseURL: process.env.REACT_APP_GEO_URL,
		params
	});
	return response.data ? response.data : {};
};

export const updateClientPhonesAction = async (id, data) => {
	const response = await api.put(`/clients/${id}/update-phones`, data);
	return response.data ? response.data : [];
};

export const updateClientAddressesAction = async (id, data) => {
	const response = await api.put(`/clients/${id}/update-addresses`, data);
	return response.data ? response.data : [];
};

export const createClientAction = async (values) => {
	const response = await api.post(`/clients`, values);
	return response.data || {};
};

export const updateClientAction = async (id, values) => {
	const response = await api.put(`/clients/${id}`, values);
	return response.data || {};
};

export const getClientsAction = async (values = {}) => {
	const { last_order_date = [], ...rest } = values;
	const params = {
		...rest,
		last_order_date:
			last_order_date[0] && last_order_date[1]
				? `${dayjs(last_order_date[0]).format(dateServerFormat)} - ${dayjs(last_order_date[1]).format(dateServerFormat)}`
				: null
	};
	const response = await api.get(`/clients`, { params });
	return response || {};
};

export const deleteClientAction = async (id) => {
	const { success } = await api.delete(`/clients/${id}`);
	return !!success;
};

export const getClientFromPhoneAction = async (number) => {
	let client = {};
	if (number.length === 13) {
		const list = await clientSearchPhoneAction(number);
		for (const item of list) {
			let isPhone = false;
			for (const phone of item?.phones || []) {
				if (number === phoneClear(phone.value)) {
					isPhone = true;
					client = item;
					break;
				}
			}
			if (isPhone) {
				break;
			}
		}
	}
	return client;
};

export const clientExportAction = async (values = {}) => {
	const { last_order_date = [], ...rest } = values;
	const params = {
		...rest,
		last_order_date:
			last_order_date[0] && last_order_date[1]
				? `${dayjs(last_order_date[0]).format(dateServerFormat)} - ${dayjs(last_order_date[1]).format(dateServerFormat)}`
				: null
	};
	const response = await api.get(`/clients/export`, { params });
	return !!response.success;
};
